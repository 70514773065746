<template>
  <div class="item">
    <div class="item-header">
      <div class="title">{{ data.title }}</div>
      <div class="time">更新时间：{{ data.updateTime }}</div>
    </div>
    <div class="item-content">
      <van-image
        v-if="data.imageURL"
        width="50px"
        height="50px"
        :src="data.imageURL"
      />
      <div class="text">{{ simpleText(data.introduction) }}</div>
      <van-button type="primary" size="small" @click="$emit('share')">
        分享
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Image } from 'vant'

export default {
  components: {
    [Button.name]: Button,
    [Image.name]: Image
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    // 截取前 20 个字符
    simpleText(text) {
      if (text.length < 20) return text
      return text.substr(0, 20) + '...'
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.item {
  background: @white;
  font-size: 14px;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    padding: 4px;
    > .title {
      flex: 1;
      font-weight: bold;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    padding: 4px;
    > .van-image {
      margin-right: 8px;
    }
    > .text {
      flex: 1;
      padding-right: 8px;
    }
  }
}
</style>
