<template>
  <!-- 下拉刷新 -->
  <van-pull-refresh
    style="min-height: 100%"
    v-model="refreshing"
    @refresh="onRefresh"
  >
    <!-- 列表 -->
    <van-list
      :loading="loading"
      :error="error"
      :finished="finished"
      :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
      error-text="请求失败，点击重新加载"
      @load="onNext"
    >
      <!-- 素材库信息 -->
      <item
        v-for="item in list"
        :key="item"
        :data="item"
        @share="handleShare(item)"
      />
    </van-list>
  </van-pull-refresh>
  <!-- 分享选择面板 -->
  <van-action-sheet
    v-model:show="show"
    :actions="actions"
    cancel-text="取消"
    @select="handleSelect"
  />
</template>

<script>
import { List, PullRefresh, ActionSheet } from 'vant'
import { getMaterialList } from '../../../service/material.service'
import { shareType } from '../../../utils/constants'
import { pagination } from '../../../utils/pagination'
import Item from './_item.vue'

// 根据 lable 返回对应的 key
const findShareKey = (label) => {
  for (const key in shareType) {
    if (shareType[key].label === label) {
      return key
    }
  }
}

export default {
  name: 'Meterial',
  components: {
    Item,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [ActionSheet.name]: ActionSheet
  },
  data() {
    const _share = Object.values(shareType)

    return {
      show: false,
      actions: Array.from(_share, ({ label }) => ({ name: label }))
    }
  },
  setup() {
    const {
      list,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getMaterialList, { pageMode: true })

    return {
      list,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    }
  },
  methods: {
    handleShare(item) {
      this.show = true
      this.__cache = item
    },
    handleSelect(item) {
      console.log('xx', item)
      console.log('xxx', this.__cache)
      this.$router.push({
        name: 'material-guide',
        params: { id: this.__cache.id },
        query: {
          type: findShareKey(item.name)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
